
import { IArticle, IArticleAuthor, IArticleCategory } from './article.types';
import axios from 'axios';

export function fetchArticles(
    errorCallback: (error: string) => void,
    categoryCallback: (data: IArticleCategory[]) => void,
    authorCallback: (data: Map<number, IArticleAuthor>) => void,
    articleCallback: (data: IArticle[]) => void,
) {

    const instance = axios.create({
        baseURL: ''
    });

    // Alter defaults after instance has been created
    /* tslint:disable:no-string-literal */
    delete instance.defaults.headers.common['Authorization'];
    /* tslint:enable:no-string-literal */


    axios.get("https://kommun.varannanvecka.app/wp-json/wp/v2/categories?per_page=100")
        .then(
            response => {
                if (response.status === 200) {
                    categoryCallback(getCategoryList(response.data));
                } else {
                    errorCallback("Server not available for main categories " + response.status);
                }
            },
        ).catch(error => {
            errorCallback("Server request failed for main categories" + error);
        });

    axios.get("https://kommun.varannanvecka.app/wp-json/wp/v2/users")
        .then(
            response => {
                if (response.status === 200) {
                    authorCallback(getAuthorMap(response.data));
                } else {
                    errorCallback("Server not available for authors " + response.status);
                }
            },
        ).catch(error => {
            errorCallback("Server request failed for authors" + error);
        });
    axios.get("https://kommun.varannanvecka.app/wp-json/wp/v2/posts?per_page=100")
        .then(
            response => {
                if (response.status === 200) {
                    articleCallback(getArticles(response.data));
                } else {
                    errorCallback("Server not available for articles " + response.status);
                }
            },
        ).catch(error => {
            errorCallback("Server request failed for articles" + error);
        });
    axios.get("https://kommun.varannanvecka.app/wp-json/wp/v2/posts?per_page=100&page=2")
        .then(
            response => {
                if (response.status === 200) {
                    articleCallback(getArticles(response.data));
                } else {
                    errorCallback("Server not available for articles " + response.status);
                }
            },
        ).catch(error => {
            errorCallback("Server request failed for articles" + error);
        });

};


const getAuthorMap = (data: any[]): Map<number, IArticleAuthor> => {
    const map = new Map<number, IArticleAuthor>();
    for (const authData of data) {
        const author = convertoToAuthor(authData);
        if (author) {
            map.set(author.id, author);
        } else {
            console.error("Invalid author data fetched: " + JSON.stringify(authData));
        }
    }
    return map;
};

const convertoToAuthor = (data: any): IArticleAuthor | undefined => {

    if (!data.id || !data.name || !data.avatar_urls) {
        return undefined;
    }

    const author: IArticleAuthor = {
        id: data.id,
        name: data.name,
        avatarUrl: data.avatar_urls["48"]
    };

    return author;
};

const getCategoryList = (data: any[]): IArticleCategory[] => {
    const sublist: IArticleCategory[] = [];

    for (const catData of data) {
        if (catData.parent === 0) {
            continue;
        } else {
            const category = convertoToCategory(catData);
            if (category && category.count > 0) { sublist.push(category); }

        }
    }

    return sublist;
};


const convertoToCategory = (data: any): IArticleCategory | undefined => {

    if (!data.id || !data.name || data.count === undefined || !data.parent) {
        return undefined;
    }

    const cat: IArticleCategory = {
        id: data.id,
        name: data.name,
        count: data.count,
        order: data.description ? data.description : "",
    };

    return cat;
};

const getArticles = (data: any[]): IArticle[] => {
    const articles: IArticle[] = [];
    for (const artData of data) {
        const article = convertoToArticle(artData);
        if (article) {
            articles.push(article);
        } else {
            console.error("Invalid article data fetched: " + JSON.stringify(artData));
        }
    }
    return articles;
};
const convertoToArticle = (data: any): IArticle | undefined => {

    if (!data.id || !data.author || !data.categories || !data.excerpt
        || !data.content || !data.title || !data.slug) {
        return undefined;
    }

    let lang:"EN"|"SE" = "SE";

    

    if (data.link && data.link.indexOf("/en/") > -1)
    {
        console.log(data.link);
        lang = "EN";
    }

    const article: IArticle = {
        id: data.id,
        slug: data.slug,
        author: data.author,
        title: data.title.rendered,
        categories: data.categories,
        excerpt: data.excerpt.rendered,
        content: data.content.rendered,
        imageUrl: data.jetpack_featured_media_url,
        pointsMe: data.acf["point-me"],
        pointsCoparenting: data.acf["point-coparenting"],
        pointsChild: data.acf["point-child"],
        lang,
    };

    return article;
};