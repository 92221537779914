import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:0|1|2|3|4|undefined;
  reflectionTwo:0|1|2|3|4|undefined;
  reflectionThree:0|1|2|3|4|undefined;
  reflectionFour:0|1|2|3|4|undefined;
  reflectionFive:string;
}

export const ExerciseLoss_title = "Upplever du känslor av förlust?";
export const ExerciseLoss_title_en = "Do you experience feelings of loss?";
export const ExerciseLoss_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/forlust-4.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'För många föräldrar innebär en separation upplevelser av en eller flera förluster. Känner du en förlust i något av följande?',
  questionOne: "Att vara en del av en kärleksrelation",
  questionTwo: "En trygg ekonomi",
  questionThree: "Att vara en kärnfamilj/annan konstellation",
  questionFour: "Att tillbringa mycket tid med barnen",
  questionFive: "Finns det någon annat som du känner förlust över? Utifrån det som du känner förlust över, sätt upp en målbild hur du skulle vilja ha det om ett par månader.",

  amountZero:'Inte alls',
  amountOne:'Lite grann',
  amountTwo:'En del',
  amountThree:'Mycket',
  amountFour:'Väldigt mycket',
  noAnswer:"Inget svar",
}

const engelska: (typeof baselanguage) = {
  intro: 'For many parents, a separation means experiences of one or more losses. Do you feel a loss in any of the following?',
  questionOne: "Being part of a love relationship",
  questionTwo: "A secure economy",
  questionThree: "Being a nuclear family/other constellation",
  questionFour: "To spend a lot of time with the children",
  questionFive: "Is there anything else that you feel at a loss of? Based on what you feel a loss of, set up a goal of how you would like to have it in a few months time.",
  amountZero:'Not at all',
  amountOne:'A bit',
  amountTwo:'Some',
  amountThree:'A lot',
  amountFour:'Very Much',
  noAnswer:"No answer",
}

export default function ExerciseLoss(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false, 
    reflectionOne:undefined,
    reflectionTwo:undefined,
    reflectionThree:undefined,
    reflectionFour:undefined,
    reflectionFive:"",
  });
  const handleChange = (name:string, value:0|1|2|3|4) => {
    setState({ ...state, [name]: value });
  };
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <div>{lang.intro}</div>

      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionOne", 0)} >{lang.amountZero}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===0)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 0)}>{0}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", 4)}>{4}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionOne", 4)}>{lang.amountFour}</div>
      </div>

      <h2>{lang.questionTwo}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionTwo", 0)} >{lang.amountZero}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===0)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 0)}>{0}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", 4)}>{4}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionTwo", 4)}>{lang.amountFour}</div>
      </div>

      <h2>{lang.questionThree}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionThree", 0)} >{lang.amountZero}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===0)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 0)}>{0}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", 4)}>{4}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionThree", 4)}>{lang.amountFour}</div>
      </div>

      <h2>{lang.questionFour}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div style={{alignSelf: 'center'}}  onClick={() =>handleChange("reflectionFour", 0)} >{lang.amountZero}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===0)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 0)}>{0}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===1)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 1)}>{1}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===2)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 2)}>{2}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===3)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 3)}>{3}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===4)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", 4)}>{4}</div>
        <div style={{alignSelf: 'center'}} onClick={() =>handleChange("reflectionFour", 4)}>{lang.amountFour}</div>
      </div>


      <h2>{lang.questionFive}</h2>
      <textarea rows={5} name="reflectionFive" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionFive} onChange={(e) => handleChangeTA(e)}></textarea>


      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {((data.reflectionOne===0)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountZero}</div>:null)}
      {((data.reflectionOne===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountOne}</div>:null)}
      {((data.reflectionOne===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountTwo}</div>:null)}
      {((data.reflectionOne===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountThree}</div>:null)}
      {((data.reflectionOne===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountFour}</div>:null)}
      {((data.reflectionOne===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionTwo}</h2>
      {((data.reflectionTwo===0)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountZero}</div>:null)}
      {((data.reflectionTwo===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountOne}</div>:null)}
      {((data.reflectionTwo===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountTwo}</div>:null)}
      {((data.reflectionTwo===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountThree}</div>:null)}
      {((data.reflectionTwo===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountFour}</div>:null)}
      {((data.reflectionTwo===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionThree}</h2>
      {((data.reflectionThree===0)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountZero}</div>:null)}
      {((data.reflectionThree===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountOne}</div>:null)}
      {((data.reflectionThree===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountTwo}</div>:null)}
      {((data.reflectionThree===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountThree}</div>:null)}
      {((data.reflectionThree===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountFour}</div>:null)}
      {((data.reflectionThree===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionFour}</h2>
      {((data.reflectionFour===0)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountZero}</div>:null)}
      {((data.reflectionFour===1)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountOne}</div>:null)}
      {((data.reflectionFour===2)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountTwo}</div>:null)}
      {((data.reflectionFour===3)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountThree}</div>:null)}
      {((data.reflectionFour===4)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.amountFour}</div>:null)}
      {((data.reflectionFour===undefined)?<div style={{whiteSpace: 'pre-wrap'}}>{lang.noAnswer}</div>:null)}

      <h2>{lang.questionFive}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionFive}</div>):null}

    </>);

  return renderToStaticMarkup(reply);
}