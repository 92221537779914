import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string,
  reflectionTwo:string,
  reflectionThree:string,
}

export const ExerciseChildTeenagers_title = "Tonåringar som ”faller mellan stolarna”";
export const ExerciseChildTeenagers_title_en = "Teenagers need special attention from both parents";
export const ExerciseChildTeenagers_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/tonaring.jpg?fit=509%2C339&ssl=1";

const baselanguage = {

  introOne: "Tonåringar behöver fortfarande gränser för att känna sig trygga.",
  introTwo: "För att få en nära relation med sin tonåring krävs känslomässig öppenhet och ärlighet från både föräldrar och barn.",
  introThree: "Det är barnet som ska kunna luta sig mot föräldern, inte tvärtom.",
  introFour: "Det är viktigt att tonåringen respekterar din gränssättning för att du ska kunna känna tillit.",
  
  questionOne :"Vilka regler och överenskommelser har du och tonåringen? Är det samma som hos medföräldern?",
  questionTwo :"Känner du en ömsesidig respekt mellan dig och din tonåring?",
  questionThree :"Hur är kommunikationen mellan dig och medföräldern? Hör ni av er till varandra eller går kommunikationen via tonåringen?",
}

const engelska: (typeof baselanguage) = {
  introOne: "Teenagers still need boundaries to feel safe.",
  introTwo: "Having a close relationship with your teenager requires emotional openness and honesty from both parents and children.",
  introThree: "It is the child who need to be able to lean on the parent, not the other way around.",
  introFour: "It is important that the teenager respects your boundaries so that you can feel trust for each other.",
 
  questionOne :"What rules and agreements do you and the teenager have? Are they the same as with the co-parent?",
  questionTwo :"Do you feel a mutual respect between you and your teenager?",
  questionThree :"How is the communication between you and the co-parent? Do you talk to each other or does the communication go through the teenager?",
}

export default function ExerciseChildTeenagers(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:"",
    reflectionTwo:"",
    reflectionThree:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <ul>
        <li>{lang.introOne}</li>
        <li>{lang.introTwo}</li>
        <li>{lang.introThree}</li>
        <li>{lang.introFour}</li>
      </ul>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionTwo" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTwo} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionThree}</h2>
      <textarea rows={5} name="reflectionThree" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionThree} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>):null}
      <h2>{lang.questionTwo}</h2>
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTwo}</div>):null}
      <h2>{lang.questionThree}</h2>
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionThree}</div>):null}
    </>);

  return renderToStaticMarkup(reply);
}