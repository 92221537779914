import * as React from 'react';
import './App.css';
import LoginProvider from 'Context/LoginContext';
import Router from 'pages/Router';


class App extends React.PureComponent {
  public render() {

    return (
        <LoginProvider>
          <Router />
        </LoginProvider>
    );
  }
}

export default App;
