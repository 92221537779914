import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingIntro3.css"

const tn: Translate = Translate.getInstance()

export default function LandingIntro() {

  return (
    <div className="muni-landing-intro-page-3">
      <div className="muni-landing-intro-container-3">
        <h1 className="muni-intro-tagline-h1-3">
          {tn.lang.vvintroMalinHeadline}
        </h1>
        <div className="muni-intro-button-container">
          <h2>{tn.lang.vvintroTeamText}</h2>
        </div>
      </div>
    </div>
  )
}
