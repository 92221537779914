import React from "react"
import "components/landing/landingStyles/landingForWork.css"
import Translate from "service/Translate"
const tn: Translate = Translate.getInstance()

export default function landingForWork() {
  const openSignupModal = () => {
    const event = new CustomEvent("openSignupModal")
    window.dispatchEvent(event)
  }

  const contactUs = () => {
    window.location.href = "mailto:hello@varannanvecka.app"
  }

  return (
    <div className="muni-landingForWork">
      <div className="muni-landingForWork-container">

        <div className="muni-landingForWork-section-1">
            <div className="muni-landingForWork-text">
                <h2>{tn.lang.reflektionForWorkHeadline}</h2>
                {/* <p>Vi är glada att vi kan erbjuda alla föräldrar ett bra digitalt föräldraskapsstöd vid separation utan kostnad - oavsett vilken kommun de bor i med sina barn!</p> */}
                <p>{tn.lang.reflektionForWorktext} <span>{tn.lang.reflektionForWorktextTwo}</span></p>
            </div>
              
            <img id="muni-landingForWork-img-1" src="./IpadReflektion.png" alt="" />
            

            <div className="muni-ForWork-button-container">
              <button className="muni-ForWork-button" onClick={openSignupModal}>
                  {tn.lang.getStarted}
              </button>
              <button className="muni-contact-us-button" onClick={contactUs}>
                {tn.lang.reflektionContactUs}
                <img
                src="./arrow-forward-red.svg"
                alt="pil pekar mot höger"
                className="muni-arrow-svg-img"
                      />
              </button>
            </div>
        </div>

        <img id="muni-landingForWork-img-2" src="./IpadReflektion.png" alt="" />


      </div>
    </div>
  )
}
