import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingHowItWorks.css"

const tn: Translate = Translate.getInstance()

export default function LandingHowItWorks() {
  return (
    <div className="muni-landing-howItWorks">
      <div className="muni-landingInfo-howItWorks-continer">
       {/* <div className="muni-landing-howItWorks-headline">
            <h1>{tn.lang.vvexplain}</h1>
          </div> */}
        <div className="muni-landingInfo-howItWorks-intro">
          <h2 className="muni-landingInfo-howItWorks-intro-title" id="muni-landing-howItWorks-subtext-first"> {tn.lang.howItWorksSubHeaders1}<span id="muni-landing-howItWorks-subtext-second">{tn.lang.howItWorksSubHeaders2}</span></h2>

          <img className="muni-landingInfo-howItWorks-mobile" id="mobile-1" src="./mobileReflektion.png" alt="bild av separationsguiden i mobilvy" />
        </div>


        <div className="muni-landingInfo-howItWorks-continer-parts">
          <div className="muni-landingInfo-howItWorks-parts">
            <h3><span>1. </span> {tn.lang.aboutMe}</h3>
            <img
              className="muni-logo-howItWorks"
              src="./self-love-color.png"
              alt="Om mig logo"
              style={{ margin: "15px" }}
            />
            <p>
              {tn.lang.aboutMeExplainArticle} 
              {tn.lang.aboutMeExplainText}
            </p>
          </div>

          <div className="muni-landingInfo-howItWorks-parts">
            <h3>  <span>2. </span> {tn.lang.coparenting}</h3>
            <img
              className="muni-logo-howItWorks"
              src="./protect-color.png"
              alt="A highfive with two hands"
              style={{ margin: "20px" }}
            />
            <p>
              {tn.lang.coparentingExplainArticle}
              {tn.lang.coparentingExplainText}
            </p>
          </div>
          
          <div className="muni-landingInfo-howItWorks-parts">
            <h3> <span>3. </span> {tn.lang.children}</h3>
            <img
              className="muni-logo-howItWorks"
              src="./children-color.png"
              alt="Barnet logo"
            />
            <p>
              {tn.lang.childrenFirstArticle}
              {tn.lang.childrenFirstText}
            </p>
          </div>
        </div>

        <div className="muni-landingInfo-howItWorks-intro">
         

          <h2 className="muni-landingInfo-howItWorks-intro-title" id="muni-landing-howItWorks-subtext-first-2">{tn.lang.howItWorksSubHeaders3}<span id="muni-landing-howItWorks-subtext-second-2">{tn.lang.howItWorksSubHeaders4}</span></h2>

          <img className="muni-landingInfo-howItWorks-mobile" id="mobile-2" src="./mobileReflektionProgress.png" alt="bild av separationsguiden i mobilvy" />
        </div>
      </div>
    </div>
  )
}
