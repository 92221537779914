import React, { useEffect, useState } from "react"
import "components/hamburgerMenu.css"
import Translate from "service/Translate"
import FlagGroup from "./FlagGroup"

const tn: Translate = Translate.getInstance()

interface HamburgerMenuProps {
  isOpen: boolean
  onClose: () => void
  showLoginButton?: boolean
}
const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  isOpen,
  onClose,
  showLoginButton = true,
}) => {
  useEffect(() => {
    if (!isOpen) {
      setMenuShowing(false)
      setMenuShowingArticle(false)
      //setMenuShowingExercise(false)
      setMenuShowingAboutUs(false)
    }
  }, [isOpen])

  const openSignupModal = () => {
    onClose()
    const event = new CustomEvent("openSignupModal")
    window.dispatchEvent(event)
  }

  let menuClass = "menu-hamburger"
  if (isOpen) {
    menuClass += " entering"
  } else {
    menuClass += " exiting"
  }
  const [menuShowing, setMenuShowing] = useState(false)
  const [menuShowingArticle, setMenuShowingArticle] = useState(false)
  //const [menuShowingExercise, setMenuShowingExercise] = useState(false)
  const [menuShowingAboutUs, setMenuShowingAboutUs] = useState(false)
  if (!isOpen) {
    return null
  }
  /*
  const redirectProfessionel = () => {
    window.location.href = "https://www.varannanvecka.app/professionella"
  }
  */

  const redirectToApp = () => {
    window.location.href = "https://varannanvecka.app/install/kommun"
  }

    const scrollToLandingHowItWorks = () => {
    onClose()
    setTimeout(() => {
      const landingForHowIrWorksSection = document.querySelector(
        ".landing-for-how-it-work-class"
      ) as HTMLElement

      if (landingForHowIrWorksSection) {
        landingForHowIrWorksSection.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  }

  const scrollToLandingForWork = () => {
    onClose()
    setTimeout(() => {
      const landingForWorkSection = document.querySelector(
        ".landing-for-work-class"
      ) as HTMLElement

      if (landingForWorkSection) {
        landingForWorkSection.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  }

  const scrollToLandingForPrivatePerson = () => {
    onClose()
    setTimeout(() => {
      const landingForWorkSection = document.querySelector(
        ".landing-for-private-person-class"
      ) as HTMLElement

      if (landingForWorkSection) {
        landingForWorkSection.scrollIntoView({ behavior: "smooth" })
      }
    }, 300)
  }

  const handleMenuShowing = () => {
    if (menuShowing === false) {
      setMenuShowing(true)
    } else {
      setMenuShowing(false)
    }
  }

  const handleMenuShowingArticle = () => {
    if (menuShowingArticle === false) {
      setMenuShowingArticle(true)
    } else {
      setMenuShowingArticle(false)
    }
  }

  const redirectToAboutUs = () => {
    window.open("https://www.varannanvecka.app/om-oss/")
  }
  /*
  const handleMenuShowingExercise = () => {
    if (menuShowingExercise === false) {
      setMenuShowingExercise(true)
    } else {
      setMenuShowingExercise(false)
    }
  }

  */
  const handleMenuShowingAboutUs = () => {
    if (menuShowingAboutUs === false) {
      setMenuShowingAboutUs(true)
    } else {
      setMenuShowingAboutUs(false)
    }
  }

  const openLogin = () => {
    onClose()
    const event = new CustomEvent("openLoginModal")
    window.dispatchEvent(event)
  }

  return (
  <div className={menuClass}>
    <img className="menu-close-icon" src="/close300Black.svg" alt="closing icon" onClick={onClose} />

    <div className="menu-hamburger-container">

      
      <div className="menu-options-container">

        <div className="menu-option">
            <div className={`menu-option-title ${menuShowing ? "menu-open" : "menu-closed"}`} onClick={handleMenuShowing} >
              <h3>{tn.lang.hamburgerHowItWorks}</h3>
              <img className={`menu-option-dropDown-icon ${menuShowing ? "arrow-up" : "arrow-down"}`}
              src="/expand300black.svg" alt="expand icon" />
            </div>

            {menuShowing && (
              <div className="menu-option-dropDown-container">
                <h2>{tn.lang.hamburgerHowItWorksHeadline}</h2>
                <div className="menu-option-dropDown-section">
                    <h3 onClick={scrollToLandingForPrivatePerson}>
                      {tn.lang.hamburgerHowItWorksPrivatePerson}
                    </h3>
                    <p onClick={scrollToLandingForPrivatePerson}>
                      {" "}
                      {tn.lang.hamburgerHowItWorksPrivatePersonText}
                    </p>
                </div>
                <div className="menu-option-dropDown-section">
                
                    <h3 onClick={scrollToLandingForWork}>
                      {tn.lang.hamburgerHowItWorksProfessional}
                    </h3>
                    <p onClick={scrollToLandingForWork}>
                      {tn.lang.hamburgerHowItWorksProfessionalText}
                    </p>
                </div>
              </div>
            )}
        </div>

        <div className="menu-option">
          <div className={`menu-option-title ${menuShowingAboutUs ? "menu-open" : "menu-closed"}`}>
            <h3>{tn.lang.hamburgerAboutUs}</h3>
            <img className={`menu-option-dropDown-icon ${menuShowingAboutUs ? "arrow-up" : "arrow-down"}`} onClick={handleMenuShowingAboutUs} src="/expand300black.svg" alt="expand icon" />
          </div>
        
          {menuShowingAboutUs && (
          <div className="menu-option-dropDown-container">
            <h2>{tn.lang.hamburgerGetToKnowUs}</h2>
            <div className="menu-option-dropDown-section">
              <h3 onClick={redirectToAboutUs}>{tn.lang.hamburgerAboutUsHeadline}</h3>
              <p onClick={redirectToAboutUs}>{tn.lang.hamburgerAboutUsHeadlineText}</p>
              </div>
            </div>
          )}
        </div>

        <div className="menu-option">
          <div className={`menu-option-title ${menuShowingArticle ? "menu-open" : "menu-closed"}`}>
            <h3> {tn.lang.hamburgerNextStep}</h3>
            <img className={`menu-option-dropDown-icon ${menuShowingArticle ? "arrow-up" : "arrow-down"}`}
            onClick={handleMenuShowingArticle} src="/expand300black.svg" alt="expand icon" />
          </div>
        
          {menuShowingArticle && (
            <div className="menu-option-dropDown-container">
              <h2>{tn.lang.hamburgerAppName}</h2>
              <div className="menu-option-dropDown-section" onClick={scrollToLandingHowItWorks}>
                <h3>{tn.lang.hamburgerLifePuzzle}</h3>
                <p>{tn.lang.hamburgerLifePuzzleText}</p>
              </div>
            <p onClick={redirectToApp} className="link-to-app">{tn.lang.hamburgerGoToApp}</p>

            </div>
          )}
        </div>

      </div>

{/* Det är här du är Casandra, rensa i klassnamnen och css */}
      <div className="menu-hamburger-button-container">
        {showLoginButton && (
          <div>
            <button
              className="menu-hamburger-login-button"
              onClick={openLogin}
            >
              {tn.lang.logIn}
            </button>
          </div>
        )}
        <p onClick={openSignupModal} className="menu-hamburger-create-account-button">{tn.lang.createAcc}</p>
        <div>
          <FlagGroup />
        </div>
      </div>
    </div>

  </div> 
  )
}
export default HamburgerMenu
