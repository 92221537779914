import React from "react"
import Translate from "service/Translate"
import { useLoginContext } from "./useLoginContext"
import "components/flagGroup.css"

const tn: Translate = Translate.getInstance()
const FlagGroup = () => {
  const context = useLoginContext()

  return (
    <div className="muni-flag-group" style={{ zIndex: 3 }}>
      <img
        className="muni-flag"
        style={{
          filter: "grayscale(" + (tn.getLanguage() === "SE" ? 0 : 1) + ")",
          width: "30px",
          margin: "1px",
          padding: "1px",
        }}
        src={require("../images/swedish-flag.png")}
        alt="SE"
        onClick={() => context.setLang("SE")}
      />
      <img
        className="muni-flag"
        style={{
          filter: "grayscale(" + (tn.getLanguage() === "EN" ? 0 : 1) + ")",
          width: "30px",
          margin: "1px",
          padding: "1px",
        }}
        src={require("../images/UK-flag.png")}
        alt="GB"
        onClick={() => context.setLang("EN")}
      />
    </div>
  )
}

export default FlagGroup
