import React, { useEffect, useRef, useState } from "react"
import LandingIntro from "components/landing/LandingIntro"
import LandingIntro2 from "components/landing/LandingIntro2"
import LandingIntro3 from "components/landing/LandingIntro3"

import LandingYoutube from "components/landing/LandingYoutube"
import "pages/styles/landingpage.css"
// import LandingMalin from "components/landing/LandingMalin"
import LandingHowItWorks from "components/landing/LandingHowItWorks"
import LandingArticleAmount from "components/landing/LandingArticleAmount"
import LandingExperts from "components/landing/LandingExperts"
//import FlagGroup from "components/FlagGroup"
import HamburgerMenu from "components/HamburgerMenu"
import LandingForWork from "components/landing/LandingForWork"
import Translate from "service/Translate"
import LandingSignup from "components/landing/LandingSignup"
import LandingFooter from "components/landing/LandingFooter"
import ChangeLanguage from "components/ChangeLanguage"


const tn: Translate = Translate.getInstance()

export default function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const scrollToBottom = () => {
    console.log("hej")
    const event = new CustomEvent("openLoginModal")
    window.dispatchEvent(event)
  }

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [isMenuOpen])

  const [activeSection, setActiveSection] = useState(0)
  const sectionRefs = useRef<(HTMLElement | null)[]>([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionRefs.current.indexOf(
              entry.target as HTMLElement
            )
            setActiveSection(index)
          }
        })
      },
      { threshold: 0.2 }
    )

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref)
    })

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref)
      })
    }
  }, [])

  return (
    <div className="page-landing-container">
      <LandingSignup />
      <HamburgerMenu
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        showLoginButton={true}
      />

      <main className="page-landing-main">

        <div className="page-landing-header">
          <img
            className={`muni-intro-landingIntro-logo ${
              isMenuOpen ? "hide-logo" : ""
            }`}
            src="/VV-logo.svg"
            alt="Varannan Vecka logo"
          />

          <div className="page-landing-header-right-part">
            <button
              className="page-landing-header-right-part-button"
              onClick={scrollToBottom}
            >
              {tn.lang.logIn}
            </button>
            <ChangeLanguage/>
            <img
              className="hamburger-menu"
              onClick={toggleMenu}
              src="/menu300.svg"
              alt="hamburger menu"
              height="38"
              color="white"
            />
          </div>
        </div>

        <section ref={(el) => (sectionRefs.current[0] = el)}>
          <LandingIntro />
        </section>

        <section
          ref={(el) => (sectionRefs.current[1] = el)}
          className="landing-for-private-person-class"
        >
          <LandingHowItWorks />
        </section>

        <section ref={(el) => (sectionRefs.current[2] = el)}>
          <LandingArticleAmount />
        </section>

        

        <section
          ref={(el) => (sectionRefs.current[3] = el)}
          className="landing-for-work-class"
        >
          <LandingForWork />
        </section>

        <section  ref={(el) => (sectionRefs.current[4] = el)}>
          <LandingIntro3 />
        </section>

        <section ref={(el) => (sectionRefs.current[5] = el)}>
          <LandingExperts />
        </section>

        <section  ref={(el) => (sectionRefs.current[6] = el)}>
          <LandingIntro2 />
        </section>

        <section ref={(el) => (sectionRefs.current[7] = el)} className="landing-for-how-it-work-class">
          <LandingYoutube />
          <LandingFooter />
        </section>

        <div className={`navigation-dots ${isMenuOpen ? "hidden" : ""}`}>
          {Array.from({ length: 8 }).map((_, index) => {
            return (
              <span
                key={index}
                className={`dot ${index === activeSection ? "active" : ""}`}
                onClick={() =>
                  sectionRefs.current[index]?.scrollIntoView({
                    behavior: "smooth",
                  })
                }
              />
            )
          })}
        </div>
      </main>
    </div>
  )
}
