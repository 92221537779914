import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"
import FlipCard from './FlipCard';

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:boolean;
  reflectionTwo:boolean;
  reflectionThree:boolean;
  reflectionFour:boolean;
  reflectionFive:boolean;
  reflectionSix:boolean;
  reflectionSeven:boolean;
  reflectionEight:boolean;
  reflectionNine:boolean;
  reflectionTen:boolean;
  reflectionEleven:boolean;
  reflectionTwelve:boolean,
  reflectionThirteen:boolean,
  reflectionFourteen:boolean,
  reflectionFifteen:boolean,
  reflectionSixteen:string,
}

export const ExerciseCozyMoments_title = "Så kan du skapa mysiga stunder";
export const ExerciseCozyMoments_title_en = "How to create those special moments";
export const ExerciseCozyMoments_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/istockphoto-1330084425-170667a.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'När vi bor med barnen halva tiden eller har umgänge med barnen behöver vi skapa nya vanor tillsammans. De är de små stunderna som ofta är kittet i vardagen men som ibland är lätta att glömma bort. ',
  questionOne: 'Nedan finns förslag om ni behöver inspiration till nya stunder hos er (vänd de kort du vill spara):',
  questionTwo: 'Finns det något annat barnet skulle uppskatta att ni gör tillsammans, vad är viktigt när ni gör saker tillsammans?',
  reflectionOne: "Mysiga frukostar med det där goda rostbrödet ",
  reflectionTwo: "Ha tid att leka med isen vattenpölen på väg från förskolan/skolan.",
  reflectionThree:"Ta samma väg till skolan och jobbet (när barnen blir äldre)",
  reflectionFour:"Gör ett hemmaspa",
  reflectionFive: "Läsa en bok tillsammans vid nattningen",
  reflectionSix: "Laga och äta middag tillsammans",
  reflectionSeven: "Kamma hår/göra flätor",
  reflectionEight: "Baka",
  reflectionNine: "Pyssla /rita /måla /bygga /plantera",
  reflectionTen: "Vara i skogen",
  reflectionEleven: "Baklängesmiddag (efterrätt först, huvudrätt och avsluta med förrätt)",
  reflectionTwelve: "Spela brädspel (lägg alla mobiler på laddning!)",
  reflectionThirteen: "Spela datorspel tillsammans",
  reflectionFourteen: "Köra singstar tillsammans",
  reflectionFifteen: "Ta hand om husdjur",
}

const engelska: (typeof baselanguage) = {
  intro: 'When we live with the children half the time or spend time with the children, we need to create new habits together. The the small special moments of everyday life sometimes easy to forget but often very important to the child. ',
  questionOne: 'Below are suggestions if you need inspiration to create new spceial moments (turn the cards you want to save):',
  questionTwo: 'Is there anything else the child would appreciate you doing together and what is important when you do things together?',

  reflectionOne: "Cozy breakfasts with that delicious toast",
  reflectionTwo: "Have time to play with the ice in the puddle on the way from preschool.",
  reflectionThree:"Take the same route to school and work (when the kids get older)",
  reflectionFour:"Create a SPA at home",
  reflectionFive: "Reading a book together at bedtime",
  reflectionSix: "Cooking and eating dinner together",
  reflectionSeven: "Comb hair/make braids",
  reflectionEight: "Bake",
  reflectionNine: "Craft / draw / paint / build / plant",
  reflectionTen: "Being in the forest",
  reflectionEleven: "Reverse dinner (dessert first, main course and finish with appetizer)",
  reflectionTwelve: "Play board games (put all mobiles on charge!)",
  reflectionThirteen: "Play computer games together",
  reflectionFourteen: "Play singstar together",
  reflectionFifteen: "Taking care of pets",
}

export default function ExerciseCozyMoments(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:false,
    reflectionTwo:false,
    reflectionThree:false,
    reflectionFour:false,
    reflectionFive:false,
    reflectionSix:false,
    reflectionSeven:false,
    reflectionEight:false,
    reflectionNine:false,
    reflectionTen:false,
    reflectionEleven:false,
    reflectionTwelve:false,
    reflectionThirteen:false,
    reflectionFourteen:false,
    reflectionFifteen:false,
    reflectionSixteen:"",
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <p>{lang.intro}</p>
      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-flip-box">

        <FlipCard 
          front={lang.reflectionOne+"?"}
          back={lang.reflectionOne+"."}
          showSide={state.reflectionOne?'back':'front'}
          onClick={() =>{handleChange("reflectionOne",!state.reflectionOne);}}
        />
        <FlipCard 
          front={lang.reflectionTwo+"?"}
          back={lang.reflectionTwo+"."}
          showSide={state.reflectionTwo?'back':'front'}
          onClick={() =>{handleChange("reflectionTwo",!state.reflectionTwo);}}
        />
        <FlipCard 
          front={lang.reflectionThree+"?"}
          back={lang.reflectionThree+"."}
          showSide={state.reflectionThree?'back':'front'}
          onClick={() =>{handleChange("reflectionThree",!state.reflectionThree);}}
        />
        <FlipCard 
          front={lang.reflectionFour+"?"}
          back={lang.reflectionFour+"."}
          showSide={state.reflectionFour?'back':'front'}
          onClick={() =>{handleChange("reflectionFour",!state.reflectionFour);}}
        />
        <FlipCard 
          front={lang.reflectionFive+"?"}
          back={lang.reflectionFive+"."}
          showSide={state.reflectionFive?'back':'front'}
          onClick={() =>{handleChange("reflectionFive",!state.reflectionFive);}}
        />
        <FlipCard 
          front={lang.reflectionSix+"?"}
          back={lang.reflectionSix+"."}
          showSide={state.reflectionSix?'back':'front'}
          onClick={() =>{handleChange("reflectionSix",!state.reflectionSix);}}
        />
        <FlipCard 
          front={lang.reflectionSeven+"?"}
          back={lang.reflectionSeven+"."}
          showSide={state.reflectionSeven?'back':'front'}
          onClick={() =>{handleChange("reflectionSeven",!state.reflectionSeven);}}
        />
        <FlipCard 
          front={lang.reflectionEight+"?"}
          back={lang.reflectionEight+"."}
          showSide={state.reflectionEight?'back':'front'}
          onClick={() =>{handleChange("reflectionEight",!state.reflectionEight);}}
        />
        <FlipCard 
          front={lang.reflectionNine+"?"}
          back={lang.reflectionNine+"."}
          showSide={state.reflectionNine?'back':'front'}
          onClick={() =>{handleChange("reflectionNine",!state.reflectionNine);}}
        />
        <FlipCard 
          front={lang.reflectionTen+"?"}
          back={lang.reflectionTen+"."}
          showSide={state.reflectionTen?'back':'front'}
          onClick={() =>{handleChange("reflectionTen",!state.reflectionTen);}}
        />
        <FlipCard 
          front={lang.reflectionEleven+"?"}
          back={lang.reflectionEleven+"."}
          showSide={state.reflectionEleven?'back':'front'}
          onClick={() =>{handleChange("reflectionEleven",!state.reflectionEleven);}}
        />
        <FlipCard 
          front={lang.reflectionTwelve+"?"}
          back={lang.reflectionTwelve+"."}
          showSide={state.reflectionTwelve?'back':'front'}
          onClick={() =>{handleChange("reflectionTwelve",!state.reflectionTwelve);}}
        />
        <FlipCard 
          front={lang.reflectionThirteen+"?"}
          back={lang.reflectionThirteen+"."}
          showSide={state.reflectionThirteen?'back':'front'}
          onClick={() =>{handleChange("reflectionThirteen",!state.reflectionThirteen);}}
        />
        <FlipCard 
          front={lang.reflectionFourteen+"?"}
          back={lang.reflectionFourteen+"."}
          showSide={state.reflectionFourteen?'back':'front'}
          onClick={() =>{handleChange("reflectionFourteen",!state.reflectionFourteen);}}
        />
        <FlipCard 
          front={lang.reflectionFifteen+"?"}
          back={lang.reflectionFifteen+"."}
          showSide={state.reflectionFifteen?'back':'front'}
          onClick={() =>{handleChange("reflectionFifteen",!state.reflectionFifteen);}}
        />
      </div>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionSixteen" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionSixteen} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionOne}</div>):null}
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTwo}</div>):null}
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionThree}</div>):null}
      {data.reflectionFour?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFour}</div>):null}
      {data.reflectionFive?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFive}</div>):null}
      {data.reflectionSix?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSix}</div>):null}
      {data.reflectionSeven?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSeven}</div>):null}
      {data.reflectionEight?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionEight}</div>):null}
      {data.reflectionNine?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionNine}</div>):null}
      {data.reflectionTen?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTen}</div>):null}
      {data.reflectionEleven?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionEleven}</div>):null}
      {data.reflectionTwelve?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTwelve}</div>):null}
      {data.reflectionThirteen?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionThirteen}</div>):null}
      {data.reflectionFourteen?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFourteen}</div>):null}
      {data.reflectionFifteen?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFifteen}</div>):null}
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionSixteen}</div>
    </>);

  return renderToStaticMarkup(reply);
}