import React from "react"
import Translate from "service/Translate"
import { useLoginContext } from "./useLoginContext"
import { Link } from "react-router-dom"

const tn: Translate = Translate.getInstance()
const LinkToAppButton = () => {
  const context = useLoginContext()

  return (
    <div>
      {context.user && context.user.id > 0 ? (
        <Link
          to={
            tn.getLanguage() === "SE"
              ? "/panel/article/sa-funkar-det"
              : "/panel/article/get-started-in-varannan-vecka/"
          }
          style={{ textDecoration: "none", alignSelf: "center" }}
        >
          <div
            className="muni-button muni-button-sub"
            style={{
              position: "relative",
              minHeight: "40px",
              paddingLeft: "38px",
            }}
          >
            <img
              style={{
                position: "absolute",
                left: "3px",
                top: "3px",
              }}
              src={require("../images/app.png")}
              alt="App"
            />
            <div>
              <div>{tn.lang.goapp}</div>
              <div>{tn.lang.goapp2}</div>
            </div>
          </div>
        </Link>
      ) : null}
    </div>
  )
}

export default LinkToAppButton
