import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import "components/articles/articleStyles/articlepage.css"
import { exercies } from "components/exercises/Exercises"
import Translate from "service/Translate"

const tn: Translate = Translate.getInstance()

interface IState {
  didMount: boolean
  submitted: boolean
}

interface IProps extends RouteComponentProps {
  exerciseDone: (slug: string, result: string) => void
  reCalculate: () => void
}

interface IRouteParams {
  slug?: string
}

class ExercisePage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      didMount: false,
      submitted: false,
    }
  }

  public componentDidMount(): void {
    setTimeout(() => this.setState({ didMount: true }), 200)
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    const { slug } = this.props.match.params as IRouteParams
    const oldSlug = prevProps.match.params["slug"]
    if (
      this.state.didMount === false &&
      oldSlug !== undefined &&
      slug !== undefined &&
      oldSlug !== slug
    ) {
      this.setState({ didMount: true, submitted: false })
      const elmnt = document.getElementById("card-image")
      if (elmnt) {
        elmnt.scrollTo(0, 0)
      }
    }
  }

  public render() {
    const { slug } = this.props.match.params as IRouteParams
    const exercise = exercies.find((exe) => exe.slug === slug)

    return (
      <div
        className="muni-articlepage"
        style={
          this.state.didMount ? { backgroundColor: "rgb(0 0 0 / 33%)" } : {}
        }
      >
        <div
          className="muni-bigarticle-card"
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={this.state.didMount ? { left: "0vw" } : {}}
        >
          <div className="muni-bigarticle-close" onClick={() => this.close()}>
            X
          </div>
          <div className="muni-bigarticle-content" id="card-image">
            {exercise ? (
              <>
                <div
                  className="muni-bigarticle-special-image"
                  style={{ backgroundImage: "url(" + exercise.imageUrl + ")" }}
                />
                <h1>
                  <img
                    style={{
                      width: "28px",
                      height: "auto",
                      marginRight: "8px",
                    }}
                    src={require("../images/task.png")}
                    alt="Icon"
                  />
                  {tn.getLanguage() === "SE"
                    ? exercise.title
                    : exercise.title_en}
                </h1>
                <exercise.exercise
                  cancel={() => this.close()}
                  submit={(response) => this.complete(slug, response)}
                />
                <div style={{ minHeight: "100px" }} />
              </>
            ) : (
              <div>Could not find exercise</div>
            )}
          </div>
        </div>
        {this.state.submitted ? (
          <div
            onClick={() => this.close()}
            style={{
              zIndex: 100,
              margin: "auto",
              position: "fixed",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div style={{ position: "relative" }}>
              <svg
                className="muni-checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="muni-checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="muni-checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  private close() {
    const { history } = this.props
    this.setState({ didMount: false })
    setTimeout(() => {
      this.props.reCalculate()
      history.push("/panel")
    }, 200)
  }

  private complete(slug: string | undefined, response: string) {
    if (slug !== undefined) {
      this.props.exerciseDone(slug, response)
    }
    const { history } = this.props
    this.setState({ submitted: true })
    setTimeout(() => {
      this.setState({ didMount: false })
    }, 1800)
    setTimeout(() => {
      this.props.reCalculate()
      history.push("/panel")
    }, 2000)
  }
}

export default withRouter(ExercisePage)
