import React from "react"
import "components/articles/articleStyles/progress.css"

interface IProps {
  progress: number
  label: string
  selected?: boolean
  selectCategory: () => void
}

interface IState {
  progress: number
  currentValue: number
}

class Progress extends React.Component<IProps, IState> {
  private timer: NodeJS.Timeout | undefined
  private counter = setInterval(() => this.countUp(), 100)

  constructor(props: IProps) {
    super(props)
    this.state = {
      progress: 0,
      currentValue: 0,
    }
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(() => {
      this.setState({ progress: props.progress })
      clearInterval(this.counter)
      this.counter = setInterval(
        () => this.countUp(),
        2000 / Math.abs(this.props.progress - this.state.currentValue)
      )
    }, 500)
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevProps.progress !== this.props.progress) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.setState({ progress: this.props.progress })
        clearInterval(this.counter)

        this.counter = setInterval(
          () => this.countUp(),
          2000 / Math.abs(this.props.progress - this.state.currentValue)
        )
      }, 500)
    }
  }

  public render() {
    const offset = 220 - 220 * (Math.min(this.state.progress, 100) / 100)
    return (
      <div
        className="muni-progress"
        onClick={() => this.props.selectCategory()}
      >
        <div className="muni-progress-figure">
          <div className="muni-outer">
            <div className="muni-inner">{this.state.currentValue}%</div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="80px"
            height="80px"
          >
            <defs>
              <linearGradient id="GradientColor">
                <stop offset="0%" stopColor="#f65a55" />
                <stop offset="100%" stopColor="#603960" />
              </linearGradient>
            </defs>
            <circle
              cx="40"
              cy="40"
              r="35"
              strokeLinecap="round"
              style={{ strokeDashoffset: offset }}
            />
          </svg>
        </div>
        <div
          className="muni-progress-label"
          style={this.props.selected ? { color: "#df6158" } : {}}
        >
          {this.props.label}
        </div>
      </div>
    )
  }

  private countUp() {
    if (this.state.currentValue < this.state.progress) {
      this.setState({ currentValue: this.state.currentValue + 1 })
    } else {
      clearInterval(this.counter)
    }
  }
}

export default Progress
