import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingArticleAmount.css"
const tn: Translate = Translate.getInstance()

export default function LandingArticleAmount() {
  return (
    <div className="muni-intro-article-amount">
      <div className="muni-intro-article-amount-conatiner">
      <div className="muni-intro-articles">
        {/* <img
          src="https://www.varannanvecka.app/wp-content/uploads/2021/03/brayan-espitia-ppknklrrKpA-unsplash-scaled.jpg"
          alt="Liten tjej som ger tummen upp"
        /> */}
        <div className="muni-intro-article-text">
          <h2>33</h2>
          <p>{tn.lang.articleAmountChildren}</p>
        </div>
      </div>
      <div className="muni-intro-articles">
        {/* <img
          src="https://www.varannanvecka.app/wp-content/uploads/2023/01/start.jpg"
          alt="bild på solupgång "
        /> */}
        <div className="muni-intro-article-text">
          <h2>43</h2>
          <p>{tn.lang.articleAmountCoparenting}</p>
        </div>
      </div>
      <div className="muni-intro-articles">
        {/* <img
          src="https://www.varannanvecka.app/wp-content/uploads/2021/08/nick-fewings-ka7REB1AJl4-unsplash.jpg"
          alt="Ritad figur som hänger i ett hjärta"
        /> */}
        <div className="muni-intro-article-text">
          <h2>14</h2>
          <p>{tn.lang.articleAmountAboutMe}</p>
        </div>
      </div>
      </div>
    </div>
  )
}
