import { useLoginContext } from "components/useLoginContext"
import React, { useState } from "react"
import Translate from "service/Translate"
import "./landingStyles/landingLogin.css"
const tn: Translate = Translate.getInstance()

interface IProps {
  createAccount: () => void
  forgotPassword: () => void
  displayAllOptions: () => void
}

interface IState {
  email: string
  password: string
  code: string
  anon: boolean
}

export default function LandingLogin(props: IProps) {
  const context = useLoginContext()
  const [state, setState] = React.useState<IState>({
    email: "",
    password: "",
    code: "",
    anon: false,
  })

  const [isValidEmail, setIsValidEmail] = useState<boolean>(true)

  const testValidEmail = (email: string): boolean => {
    const emailRegex = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/
    return emailRegex.test(email.trim())
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
    if (name === "email") {
      setIsValidEmail(testValidEmail(value))
    }
  }

  return (
    <div className="muni-login-form-conatiner">
      <form className="muni-login-form">
        <div className="muni-signup-header">{tn.lang.logIn}</div>
        <div className="muni-login-inputs">
        {state.anon ? null : (
          <>
            <input
              name="email"
              type="text"
              className="muni-input"
              placeholder={tn.lang.email}
              value={state.email}
              onChange={handleChange}
            />
            {!isValidEmail && (
              <label className="error">{tn.lang.invalidEmail}</label>
            )}
          </>
        )}
        {state.anon ? null : (
          <input
            name="password"
            type="password"
            className="muni-input"
            placeholder="Lösenord"
            value={state.password}
            onChange={(e) => handleChange(e)}
          />
        )}
        {state.anon ? (
          <input
            name="code"
            type="text"
            className="muni-input"
            placeholder="Kod från kommunen"
            value={state.code}
            onChange={(e) => handleChange(e)}
          />
        ) : null}
        </div>
        <div className="muni-options-forgot">
            <div onClick={() => props.forgotPassword()}>
              {tn.lang.forgottenPassword}
            </div>
        </div>

        <button
              type="button"
              className="muni-login-register-button"
              disabled={!state.anon && !isValidEmail}
              onClick={() => {
                if (state.anon) {
                  context.loginAnon(state.code)
                } else {
                  context.login({
                    email: state.email,
                    password: state.password,
                  })
                }
              }}
            >
              {tn.lang.logIn}
        </button>

        <div className="muni-options-createAcc" onClick={() => props.createAccount()}>
            <p>{tn.lang.noAcc} <span>{tn.lang.createAcc}</span></p>
        </div>
        
      </form>
    </div>
  )
}
