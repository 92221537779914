import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingIntro2.css"

const tn: Translate = Translate.getInstance()

export default function LandingIntro() {
  const openLoginModal = () => {
    const event = new CustomEvent("openSignupModal")
    window.dispatchEvent(event)
  }

  return (
    <div className="muni-landing-intro-page-2">
      <div className="muni-landing-intro-container-2">
        <h1 className="muni-intro-tagline-h1-2">
          {tn.lang.vvintroWelcomeAppName2}
        </h1>
        <div className="muni-intro-button-container">
          <button className="muni-intro-button" onClick={openLoginModal}>
            {tn.lang.getStarted}
          </button>
        </div>
      </div>
    </div>
  )
}
