import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string;
  reflectionTwo:string;
  reflectionThree:string;
  reflectionFour:string;
  reflectionFive:string;
}

export const ExerciseBuildTeam_title = "Så bygger ni ett föräldrateam";
export const ExerciseBuildTeam_title_en = "How to build a strong coparenting team";
export const ExerciseBuildTeam_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/pusselbitar.jpg?fit=508%2C339&ssl=1";

const baselanguage = {
  questionOne: 'För barn kan separationen innebära att relationen med en eller båda föräldrarna fördjupas när barnet får mer egen tid med varje förälder. Tror du att det är bra för ert barn och hur tror du att det påverkas av det?',
  questionTwo: 'Om du fick önska dig tre saker av medföräldern för ett stabilt föräldrateam kring ert barn vad skulle det vara (obs, dina frågor delas inte med medföräldern).',
  questionThree: 'Vilket sorts agerande skulle det innebära från medföräldern? ',
  questionFour: 'Vad tror du att din medförälder skulle önska sig av dig för ett stabilt föräldrateam kring ert barn? ',
  questionFive: 'Vilket sorts agerande skulle det medföra från din sida? ',
}

const engelska: (typeof baselanguage) = {
  questionOne: 'För barn kan separationen innebära att relationen med en eller båda föräldrarna fördjupas när barnet får mer egen tid med varje förälder. Tror du att Det är bra för ert barn? Om så, på vilket sätt kan du hjälpa barnet att få en bra relation med båda sina föräldrar?',
  questionTwo: 'Om du fick önska dig tre saker av medföräldern för ett stabilt föräldrateam kring ert barn vad skulle det vara (obs, dina frågor delas inte med medföräldern).',
  questionThree: 'Vilket sorts agerande skulle det innebära från medföräldern? ',
  questionFour: 'Vad tror du att din medförälder skulle önska sig av dig för ett stabilt föräldrateam kring ert barn? ',
  questionFive: 'Vilket sorts agerande skulle det medföra från din sida? ',
}

export default function ExerciseBuildTeam(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false, 
    reflectionOne:"",
    reflectionTwo:"",
    reflectionThree:"",
    reflectionFour:"",
    reflectionFive:"",
  });
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

//  <input name="reflection" type="text" className="muni-input" placeholder={tn.lang.email} value={state.reflection} onChange={(e) => handleChange(e)}/>

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionTwo" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTwo} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionThree}</h2>
      <textarea rows={5} name="reflectionThree" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionThree} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionFour}</h2>
      <textarea rows={5} name="reflectionFour" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionFour} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionFive}</h2>
      <textarea rows={5} name="reflectionFive" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionFive} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTwo}</div>
      <h2>{lang.questionThree}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionThree}</div>
      <h2>{lang.questionFour}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionFour}</div>
      <h2>{lang.questionFive}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionFive}</div>
    </>);

  return renderToStaticMarkup(reply);
}