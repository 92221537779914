import React, { useState, useEffect } from "react"

export interface IYoutubeEmbed {
  embedId: string
}

const YoutubeEmbed = (props: IYoutubeEmbed) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 760)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const iframeStyle = {
    padding: 0,
    maxWidth: "100%",
    borderRadius: "10px",
    width: isMobile ? "100vw" : "690px",
    height: isMobile ? "300px" : "300px",
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <iframe
        style={iframeStyle}
        width={isMobile ? "100vw" : "690"}
        height={isMobile ? "300" : "400"}
        frameBorder="0"
        src={`https://www.youtube.com/embed/${props.embedId}`}
        allow="autoplay;"
        title="Varannan Vecka"
      />
    </div>
  )
}

export default YoutubeEmbed
