import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:boolean|undefined;
  reflectionTwo:boolean|undefined;
  reflectionThree:boolean|undefined;
  reflectionFour:boolean|undefined;
}

export const ExerciseWhyCoparent_title = "Vad betyder föräldrasamarbete?";
export const ExerciseWhyCoparent_title_en = "What does coparenting mean?";
export const ExerciseWhyCoparent_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/foraldrahander.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'Syftet är att väcka tankar och göra det lättare att minnas varför ett stabilt föräldrasamarbete är viktigt för barn (och deras föräldrar). Fundera på vad varje svar betyder för barnet.',
  questionOne: 'Det viktigaste för barnet är att ni fortsätter att vara dess föräldrar, inte vad som hänt i er vuxenrelation.',
  questionTwo: 'Föräldrar är olika. Ni bidrar var och en för sig (och tillsammans om det funkar) med unika sidor som är värdefulla för barnet.',
  questionThree: 'Ansvaret för kommunikation, logistik och planering kring barnet är föräldrarnas - inte barnets.',
  questionFour: 'Ett stabilt föräldrateam möjliggör för barnet att leva i en mental värld fastän det bor på två ställen eller har föräldrar på olika håll.',
  yes: 'Ja',
  no:'Nej',
  noAnswer:"Inget svar",
}

const engelska: (typeof baselanguage) = {
  intro: 'The purpose is to reflect over why a good cooperating relationship is important for children (and their parents).',
  questionOne: 'The most important thing for the child is that you continue to be its parents, not what has happened in your adult relationship.',
  questionTwo: 'Parents are different. You each contribute in your own unique way (and together if it works) with valuable aspects for the child.',
  questionThree: "The responsibility for communication, logistics and planning around the child is the parents' - not the child's.",
  questionFour: 'A stable parent team allows the child to live in a mental world even though it lives in two places or has parents in different locations.',
  yes: 'Yes',
  no:'No',
  noAnswer:"No answer",
}

export default function ExerciseWhyCoparent(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false, 
    reflectionOne:undefined,
    reflectionTwo:undefined,
    reflectionThree:undefined,
    reflectionFour:undefined,
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <div>{lang.intro}</div>
      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===true)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", true)}>{lang.yes}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionOne===false)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionOne", false)}>{lang.no}</div>
      </div>
      <h2>{lang.questionTwo}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===true)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", true)}>{lang.yes}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionTwo===false)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionTwo", false)}>{lang.no}</div>
      </div>
      <h2>{lang.questionThree}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===true)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", true)}>{lang.yes}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionThree===false)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionThree", false)}>{lang.no}</div>
      </div>
      <h2>{lang.questionFour}</h2>
      <div className="muni-exercise-clickanswer-box">
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===true)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", true)}>{lang.yes}</div>
        <div className={("muni-exercise-clickanswer"+((state.reflectionFour===false)?" muni-exercise-clickanswer-select":""))} onClick={() =>handleChange("reflectionFour", false)}>{lang.no}</div>
      </div>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{(data.reflectionOne===true?lang.yes:(data.reflectionOne===false?lang.no:lang.noAnswer))}</div>
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{(data.reflectionTwo===true?lang.yes:(data.reflectionOne===false?lang.no:lang.noAnswer))}</div>
      <h2>{lang.questionThree}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{(data.reflectionThree===true?lang.yes:(data.reflectionOne===false?lang.no:lang.noAnswer))}</div>
      <h2>{lang.questionFour}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{(data.reflectionFour===true?lang.yes:(data.reflectionOne===false?lang.no:lang.noAnswer))}</div>
    </>);

  return renderToStaticMarkup(reply);
}