import React from "react"
import Translate from "service/Translate"
import { useLoginContext } from "./useLoginContext"
import "components/changeLanguage.css"

const tn: Translate = Translate.getInstance()
const ChangeLanguage = () => {
  const context = useLoginContext()

  const toggleLanguage = () => {
    context.setLang(tn.getLanguage() === "SE" ? "EN" : "SE");
  };

  return (
    <div className="muni-change-lang">
      <p
        style={{
          cursor: "pointer",
          color: "white"
        }}
        onClick={toggleLanguage}
      >
        {tn.getLanguage() === "SE" ? "Change Language" : "Change Language"}
      </p>
    </div>
  )
}

export default ChangeLanguage
