import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"
import FlipCard from './FlipCard';

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:boolean;
  reflectionTwo:boolean;
  reflectionThree:boolean;
  reflectionFour:boolean;
  reflectionFive:boolean;
  reflectionSix:boolean;
  reflectionSeven:boolean;
  reflectionEight:boolean;
  reflectionNine:string;
  reflectionTen:string;
}

export const ExerciseChildReaction_title = "Vilka reaktioner känner du igen hos ert barn?";
export const ExerciseChildReaction_title_en = "What reactions do you recognize in your child?";
export const ExerciseChildReaction_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/barns-reaktion.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: 'Syftet är att väcka tankar och göra det lättare att minnas huvudpunkterna i artikeln du nyss läst. Skriv ned dina reflektioner. Om du känner igen dig i en eller flera av exemplen ovan är det sannolikt att ert barn reagerar på omställningarna av separationen. För många barn går reaktionerna över efter en tid när alla kommit in i nya rutiner. Att barnet reagerar är normalt med tanke på de stora förändringar en separation ofta innebär. ',
  questionOne: 'Barn kan reagera på olika sätt när föräldrar separerar. Känner du igen några av dessa reaktioner efter er separation:',
  questionTwo: 'Dina observationer (vänd de kort som stämmer med vad du ser):',
  questionThree: 'Bör du göra något? Vad i sådana fall?',

  reflectionOne: "Längtan efter viktiga personer",
  reflectionTwo: "Sköter sig exemplariskt",
  reflectionThree: "Arg/irriterad/ledsen oftare",
  reflectionFour: "Extra utmaningar i skolan",
  reflectionFive: "Äter sämre/annat sätt än tidigare",
  reflectionSix: "Sömnproblem",
  reflectionSeven: "Barnet tystnar/sluter sig",
  reflectionEight: "Barnet är mer högljutt/beter sig 'illa'",

}

const engelska: (typeof baselanguage) = {
  intro: 'The purpose is to provoke thought and make it easier to remember the main points of the article you just read. Write down your reflections. If you recognize yourself in one or more of the examples above, it is likely that your child is reacting to the changes of the separation. For many children, the reactions pass after a while when everyone has settled into new routines. That the child reacts is normal considering the big changes a separation often entails.',
  questionOne: 'Children can react in different ways when parents separate. Do you recognize some of these reactions after your separation:',
  questionTwo: 'Your observations (flip the cards of what you have observed):',
  questionThree: 'What can you do?',

  reflectionOne: "Longing for important people",
  reflectionTwo: "Behaving in an exemplary manner",
  reflectionThree: "Angry/annoyed/sad more often",
  reflectionFour: "More difficulties in school",
  reflectionFive: "Eating worse/different than before",
  reflectionSix: "Sleep problems",
  reflectionSeven: "The child is very quiet",
  reflectionEight: "The child is louder/behaves 'badly'",
}

export default function ExerciseChildReaction(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false,
    reflectionOne:false,
    reflectionTwo:false,
    reflectionThree:false,
    reflectionFour:false,
    reflectionFive:false,
    reflectionSix:false,
    reflectionSeven:false,
    reflectionEight:false,
    reflectionNine:"",
    reflectionTen:"",
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <div>{lang.intro}</div>
      <h2>{lang.questionOne}</h2>
      <div className="muni-exercise-flip-box">

        <FlipCard 
          front={lang.reflectionOne+"?"}
          back={lang.reflectionOne+"."}
          showSide={state.reflectionOne?'back':'front'}
          onClick={() =>{handleChange("reflectionOne",!state.reflectionOne);}}
        />
        <FlipCard 
          front={lang.reflectionTwo+"?"}
          back={lang.reflectionTwo+"."}
          showSide={state.reflectionTwo?'back':'front'}
          onClick={() =>{handleChange("reflectionTwo",!state.reflectionTwo);}}
        />
        <FlipCard 
          front={lang.reflectionThree+"?"}
          back={lang.reflectionThree+"."}
          showSide={state.reflectionThree?'back':'front'}
          onClick={() =>{handleChange("reflectionThree",!state.reflectionThree);}}
        />
        <FlipCard 
          front={lang.reflectionFour+"?"}
          back={lang.reflectionFour+"."}
          showSide={state.reflectionFour?'back':'front'}
          onClick={() =>{handleChange("reflectionFour",!state.reflectionFour);}}
        />
        <FlipCard 
          front={lang.reflectionFive+"?"}
          back={lang.reflectionFive+"."}
          showSide={state.reflectionFive?'back':'front'}
          onClick={() =>{handleChange("reflectionFive",!state.reflectionFive);}}
        />
        <FlipCard 
          front={lang.reflectionSix+"?"}
          back={lang.reflectionSix+"."}
          showSide={state.reflectionSix?'back':'front'}
          onClick={() =>{handleChange("reflectionSix",!state.reflectionSix);}}
        />
        <FlipCard 
          front={lang.reflectionSeven+"?"}
          back={lang.reflectionSeven+"."}
          showSide={state.reflectionSeven?'back':'front'}
          onClick={() =>{handleChange("reflectionSeven",!state.reflectionSeven);}}
        />
        <FlipCard 
          front={lang.reflectionEight+"?"}
          back={lang.reflectionEight+"."}
          showSide={state.reflectionEight?'back':'front'}
          onClick={() =>{handleChange("reflectionEight",!state.reflectionEight);}}
        />
      </div>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionNine" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionNine} onChange={(e) => handleChangeTA(e)}></textarea>
      <h2>{lang.questionThree}</h2>
      <textarea rows={5} name="reflectionTen" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTen} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      {data.reflectionOne?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionOne}</div>):null}
      {data.reflectionTwo?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionTwo}</div>):null}
      {data.reflectionThree?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionThree}</div>):null}
      {data.reflectionFour?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFour}</div>):null}
      {data.reflectionFive?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionFive}</div>):null}
      {data.reflectionSix?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSix}</div>):null}
      {data.reflectionSeven?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionSeven}</div>):null}
      {data.reflectionEight?(<div style={{whiteSpace: 'pre-wrap'}}>{lang.reflectionEight}</div>):null}
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionNine}</div>
      <h2>{lang.questionThree}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTen}</div>
    </>);

  return renderToStaticMarkup(reply);
}