import React from "react"
import Translate from "service/Translate"
import "./landingStyles/landingExperts.css"
const tn: Translate = Translate.getInstance()

export default function LandingExpert() {
  return (
    <div className="muni-intro-experts">
    

      <div className="muni-intro-experts-container">
        <div className="muni-intro-experts-intro">
          <h2 className="muni-intro-experts-title">{tn.lang.experts}</h2>
          <p className="muni-intro-experts-subtitle">{tn.lang.expertsText1}</p>

                    <p className="muni-intro-experts-subtitle">{tn.lang.expertsText2}</p>

          <div className="muni-intro-experts-link">
            <a className="muni-intro-experts-a" href="https://www.varannanvecka.app/om-oss/">{tn.lang.expertsButton}</a>
            <img src="./arrow-forward-purple.svg" alt="pil pekar mot höger" className="muni-arrow-svg-img"/>
          </div>
        </div>

      <div className="muni-intro-experts-parts-container">
          <div className="muni-intro-experts-part">
            <img src="./malinFyrkant.png" alt="Bild på Malin Bergström" />
            <div className="muni-intro-experts-part-text">
                <div className="muni-intro-experts-part-text-title">
                  <h3>Malin Bergström</h3>
                  <h2>{tn.lang.expertTitleMalin}</h2>
                </div>
                <p>{tn.lang.expertTextMalin}</p>
            </div>
          </div>
          <div className="muni-intro-experts-part">
              <img src="./lindaFyrkant.jpg" alt="Linda Ljunggren Syding" />
                  <div className="muni-intro-experts-part-text"> 
                    <div>
                      <h3>Linda Ljunggren Syding</h3>
                      <h2>{tn.lang.expertTitleLinda}</h2>
                    </div>
                    <p>{tn.lang.expertTextLinda}</p>
                  </div>
                {/* <p className="muni-intro-experts-part-citat">"{tn.lang.expertTextLinda}"</p> */}
          </div> 
          <div className="muni-intro-experts-part">
            <img src="./ElisabethFyrkant.jpg" alt="Elisabeth Scholander" />
            
            <div className="muni-intro-experts-part-text"> 
                <div>
                  <h3>Elisabeth Scholander</h3>
                  <h2>{tn.lang.expertTitleElisabeth}</h2>
                </div>
                <p>{tn.lang.expertTextElisabeth}</p>
              {/* <p className="muni-intro-experts-part-citat">"{tn.lang.expertTextElisabeth}"</p> */}
            </div>
          </div>
      </div>

      {/* <div className="muni-intro-experts-parts-citat">
        <h2 id="muni-intro-experts-parts-citat-first">Vi vet att när föräldrarna mår okej gör ofta barnen det också.<span id="muni-intro-experts-parts-citat-first">Därför har vi i tagit fram Reflektion, samlat med kunskap och olika perspektiv som stöd för förälder eller viktig vuxen under separation.</span></h2> 
      </div>  */}

    </div>
  </div>
  )
}
