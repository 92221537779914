import React from "react";
import Translate from "service/Translate";
import { IExerciseProps } from './Exercises';
import './exercises.css'
import { renderToStaticMarkup } from "react-dom/server"
import FlipCard from './FlipCard';

const tn: Translate = Translate.getInstance();

interface IState {
  completed:boolean,

  reflectionOne:string;
  reflectionTwo:string;
  cardOne:boolean;
  cardTwo:boolean;
  cardThree:boolean;
  cardFour:boolean;
}

export const ExerciseWhyImportantCoparent_title = "Varför är föräldrateam viktiga för barn?";
export const ExerciseWhyImportantCoparent_title_en = "Why is coparenting important?";
export const ExerciseWhyImportantCoparent_image = "https://i0.wp.com/www.varannanvecka.app/wp-content/uploads/2023/01/foraldrahander.jpg?fit=509%2C339&ssl=1";

const baselanguage = {
  intro: "Det kan låta självklart, givetvis är det viktigt för barnet att föräldrarna kan samarbeta - men varför?",
  questionOne: "Fundera över följande fråga: Varför tror du att ett stabilt föräldrateam är bra för barnet?",
  cardOne: "Föräldrar i två hem ser ofta olika saker i barnets person och vardag. Att dela insikter om barnet skapar en helhet kring barnet - för barnet och för föräldrarna. ",
  cardTwo: "När föräldrar kan samarbeta och vara vänliga med varandra visar man barnet att det är okej för barnet att älska båda föräldrarna. Barnet behöver inte dölja eller tona ner delar av sitt liv med någon av föräldrarna.",
  cardThree: "Barnet vet att det är svårare att komma undan med fuffens om föräldrarna pratar. Det är en skyddsfaktor, särskilt när de blir äldre - båda föräldrarna har koll och kommunicerar! ",
  cardFour: "En av våra viktigaste uppgifter som föräldrar är att lära våra barn hur vi bör förhålla oss till andra människor. Föräldrateam visar barnet hur man bygger trygga relationer. Det visar också att det är möjligt att gå vidare efter svåra händelser i en relation.",
  cardBack: 'Klicka för att vända kortet',
  questionTwo: "Har du fler tankar efter att ha läst korten?",
}

const engelska: (typeof baselanguage) = {
  intro: "It may sound obvious, of course it is important for the child that the parents can cooperate - but why?",
  questionOne: "Consider the following question: Why do you think a stable parenting team is good for the child",
  cardOne: "Parents in two homes often see different things in the child's person and daily life. Sharing insights about the child creates a sense of unity around the child - for the child and for the parents.",
  cardTwo: "When parents can cooperate and be friendly with each other, they show the child that it is okay for the child to love both parents. The child does not need to hide or tone down parts of their life with either parent.",
  cardThree: "The child knows that it is harder to get away with mischief when the parents talk. It is a protective factor, especially when they get older - both parents have control and communicate!",
  cardFour: "One of our most important tasks as parents is to teach our children how we should behave towards other people. Parenting team shows the child how to build secure relationships. It also shows that it is possible to continue after a crisis in a relationship.",
  cardBack: 'Click to flip the card',
  questionTwo: "Do you have more thoughts after reading the cards?",
}

export default function ExerciseWhyImportantCoparent(props:IExerciseProps) {
  const [state, setState] = React.useState<IState>({
    completed:false, 
    reflectionOne:"",
    reflectionTwo:"",
    cardOne:false,
    cardTwo:false,
    cardThree:false,
    cardFour:false,
    
  });
  const handleChange = (name:string, value:boolean) => {
    setState({ ...state, [name]: value });
  };
  const handleChangeTA = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);
  return (
    <div className="muni-exercise-card">
      <div>{lang.intro}</div>
      <h2>{lang.questionOne}</h2>
      <textarea rows={5} name="reflectionOne" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionOne} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-flip-box">

        <FlipCard 
          cardStyle={{height:'310px'}}
          front={lang.cardBack+"."}
          back={lang.cardOne}
          hideImage={true}
          showSide={state.cardOne?'back':'front'}
          onClick={() =>{handleChange("cardOne",!state.cardOne);}}
        />
        <FlipCard 
          cardStyle={{height:'310px'}}
          front={lang.cardBack+"."}
          back={lang.cardTwo}
          hideImage={true}
          showSide={state.cardTwo?'back':'front'}
          onClick={() =>{handleChange("cardTwo",!state.cardTwo);}}
        />
        <FlipCard 
          cardStyle={{height:'310px'}}
          front={lang.cardBack+"."}
          back={lang.cardThree}
          hideImage={true}
          showSide={state.cardThree?'back':'front'}
          onClick={() =>{handleChange("cardThree",!state.cardThree);}}
        />
        <FlipCard 
          cardStyle={{height:'310px'}}
          front={lang.cardBack+"."}
          back={lang.cardFour}
          hideImage={true}
          showSide={state.cardFour?'back':'front'}
          onClick={() =>{handleChange("cardFour",!state.cardFour);}}
        />
      </div>
      <h2>{lang.questionTwo}</h2>
      <textarea rows={5} name="reflectionTwo" className="muni-input" placeholder={tn.lang.yourAnswer} value={state.reflectionTwo} onChange={(e) => handleChangeTA(e)}></textarea>

      <div className="muni-exercise-actions">
        <button type="button" className="muni-button muni-button-cancel" onClick={() => {props.cancel()}}>{tn.lang.cancel}</button>    
        <button type="button" className={("muni-button"+(state.completed?" muni-button-cancel":""))} onClick={() => {
          if (!state.completed){props.submit(processForm(state)); setState({ ...state, completed: true })}}
        }>{tn.lang.save}</button>    
      </div>
    </div>
  );
}


const processForm:(data:IState)=>string = (data) => {

  const lang = (tn.getLanguage()==="SE"?baselanguage:engelska);

  const reply = (
    <>
      <h2>{lang.questionOne}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionOne}</div>
      <h2>{lang.questionTwo}</h2>
      <div style={{whiteSpace: 'pre-wrap'}}>{data.reflectionTwo}</div>
    </>);

  return renderToStaticMarkup(reply);
}